import React from 'react';

const LoadingFeature = () => {
    return (
        <React.Fragment>
            <p>Načítám data...</p>
        </React.Fragment>
    );
};

export default LoadingFeature;