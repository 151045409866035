import React, { useEffect, useState } from 'react';
import ComponentOpenerBg from "../../../ComponentOpenerBg";
import ApiClient from "../../api/ApiClient";
import ServicesForm from "../../components/ServicesForm";
import { SEO } from "../../utils/SeoUtils";
import LoadingFeature from "../../components/LoadingFeature";

const PriceList = () => {
    const apiClient = new ApiClient();
    const [priceList, setPriceList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        new ComponentOpenerBg();
        reloadData();
    }, []);

    const reloadData = () => {
        apiClient.findPriceList()
            .then(priceList => {
                setPriceList(priceList);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching price list:", error);
                setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <SEO
                title='Ceník | T&K - Účetní a daňová kancelář'
                description='Profesionální služby v oblasti účetnictví a daní. Podvojné účetnitcví, daňová evidence, mzdová a personální agenda, účetní poradenství.'
                keywords='Podvojné účetnictví, daňová evidence, přiznání daně, zpracování mzdové agendy, účetní poradenství'
                type='article'
            />
            <section className={'sp-intro'}>
                <div className="sp-intro__bg" data-opener-bg="./assets/images/styles/home/hero-bg.jpg"></div>
            </section>

            <section className={'s-content s-content--sp'}>
                <div className={'container'}>
                    <h1 className={'title title--sp-border'}>Ceník</h1>
                </div>
                <div className={'container mt-4'}>
                    <p className={'par'}>
                        Cenu za účetní služby stanovujeme na základě počtu účetních položek nebo formou měsíčního paušálu.
                    </p>
                </div>
                <div className={'container mt-4'}>

                    {!isLoading && priceList.length> 0 ? (
                        <React.Fragment>
                            {priceList.map(cat => (
                                <div key={cat.id} className={'table-wrapper mt-4'} data-pricelist-cat={`${cat.id}`}>
                                    <table className="table table--article">
                                        <thead className="thead-blue">
                                        <tr>
                                            <th scope="col">{cat.category}</th>
                                            <th scope="col">Sazba</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {cat.items.map((item, index) => (
                                            <tr key={index}>
                                                <td data-label={`${cat.category}`}>
                                                    {item.title}
                                                </td>
                                                <td data-label="Sazba">
                                                    {item.price}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </React.Fragment>
                    ) : (
                        <LoadingFeature />
                    )}
                </div>
                <div className={'container mt-4'}>
                    <p className={'par'}>
                        Uvedené ceny jsou bez DPH. Ceny jsou platné od 1. 1. 2023.
                    </p>
                    <p className={'par'}>
                        V ceně měsíčního paušálu je zahrnuto zpracování dokladů, podání přiznání k DPH, kontrolního
                        hlášení, souhrnného hlášení a účetní poradenství včetně zpracování reportů dle přání klienta.
                    </p>
                    <p className={'par'}>
                        Výše uvedené ceny za jednotlivé úkony představují spodní hranici sazeb a veškeré poskytované
                        služby jsou oceňovány individuálně dle rozsahu poskytovaných služeb.
                    </p>
                </div>
            </section>

            <ServicesForm />
        </React.Fragment>
    );
};

export default PriceList;
