//Some utils functions
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from 'react-scroll';

export const SCROLL_TARGETS = {
    SERVICES: 'scroll-services',
    CONTACT: 'scroll-contact',
    ABOUT: 'scroll-about',
    SERVICES_FORM: 'scroll-servicesForm',
    TAX_CALENDAR: 'scroll-taxCalendar',
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export const scrollToElement = (selector, path = undefined) => {
    if(path !== undefined){
        setTimeout(() => {
            scroller.scrollTo(selector, {
                duration: 800, // You can adjust the scroll duration as needed
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -80
            });
        }, 400);
    }
    else {
        scroller.scrollTo(selector, {
            duration: 800, // You can adjust the scroll duration as needed
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -80
        });
    }
}

/*
export const scrollToElement = (selector, path = null) => {
    if(path != null){
        const navigate = useNavigate();
        navigate(path);

        setTimeout(() => {
            scroller.scrollTo(selector, {
                duration: 800, // You can adjust the scroll duration as needed
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }, 300);
    }

    scroller.scrollTo(selector, {
        duration: 800, // You can adjust the scroll duration as needed
        delay: 0,
        smooth: 'easeInOutQuart'
    });

    /*const targetElement = document.querySelector(selector);
    const targetElement = document.querySelector(selector);
    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

};*/
