import React, { Component } from 'react';
import ComponentOpenerBg from "../../../ComponentOpenerBg";
import {SEO} from "../../utils/SeoUtils";

class Career extends Component {

    constructor() {
        super();
    }

    componentDidMount() {
        new ComponentOpenerBg();
    }

    render() {
        return (
            <React.Fragment>
                <SEO
                    title='Kariéra | T&K - Účetní a daňová kancelář'
                    description='Profesionální služby v oblasti účetnictví a daní. Podvojné účetnitcví, daňová evidence, mzdová a personální agenda, účetní poradenství.'
                    keywords='Podvojné účetnictví, daňová evidence, přiznání daně, zpracování mzdové agendy, účetní poradenství'
                    type='article'
                />
                <section className={'sp-intro'}>
                    <div className="sp-intro__bg" data-opener-bg="./assets/images/styles/home/hero-bg.jpg"></div>
                </section>
                <section className={'s-content s-content--sp'}>
                    <div className={'container'}>
                        <h1 className={'title title--sp-border'}>Kariéra</h1>
                    </div>
                    <div className={'container mt-4'}>
                        <div className={'alert alert--primary'}>
                            <p className={'par'}>
                                Naše kancelář spolupracuje s vysokými školami a poskytujeme odbornou praxi
                                studentům ekonomických oborů se zaměřením na účetnictví a daně.
                            </p>
                            <p className={'par'}>
                                Stáže je možné vykonávat <strong>pouze na pobočce v Brně</strong>.
                            </p>
                        </div>
                    </div>
                    <div className={'container mt-4'}>
                        <p className={'par'}>
                            Aktuálně nejsou k dispozici žádné volné pracovní pozice.
                        </p>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Career;