export const InlineCss = {
    ".btn, .footer .footer-copyright .metud a:hover, .footer .footer-links-list a, .hp-contact-link:hover, .hp-testimonials-show-more .btn:hover, .nav-list li a, .off-canvas ul a": {
        textDecoration: "none"
    },
    ".header-info__list ul li, .sticky-header__info ul li": {
        gridTemplateColumns: "24px calc(100% - 24px)",
        gridColumnGap: "16px",
        fontWeight: "500"
    },
    ".btn, .css-12vqcob-MuiInputBase-input-MuiOutlinedInput-input:focus, .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input:focus": {
        outline: "0"
    },
    ".css-14lo706, .tns-horizontal.tns-subpixel": {
        whiteSpace: "nowrap"
    },
    "#tns1, #tns1-mw": {
        transitionDuration: ".3s"
    },
    "a, address, blockquote, body, div, fieldset, footer, form, h1, h2, h3, header, html, i, img, label, legend, li, ol, p, section, span, strong, ul": {
        margin: "0",
        padding: "0",
        border: "0",
        fontSize: "100%",
        font: "inherit",
        verticalAlign: "baseline"
    },
    ".header-info__list ul li, .off-canvas .close-button, .off-canvas ul li a, .sticky-header__info ul li, .title.title--hp-opener, body, strong": {
        fontFamily: "Poppins, sans-serif",
        fontStyle: "normal"
    },
    ".hp-opener-content h1 span, .services-item:hover .services-item-hidden, footer, header, section": {
        display: "block"
    },
    "body": {
        lineHeight: "1",
        fontSize: "100%",
        position: "relative",
        overflowX: "hidden",
        width: "100%",
        color: "#000",
        fontWeight: "400"
    },
    ".off-canvas": {
        position: "fixed",
        zIndex: "1000",
        top: "0",
        left: "-100%",
        height: "100vh",
        width: "280px",
        backgroundColor: "#fff",
        overflowY: "auto",
        transition: "left .4s linear",
        boxShadow: "0 0 1rem rgba(0, 0, 0, .25)"
    },
    ".off-canvas ul": {
        listStyleType: "none",
        padding: "0 .75rem",
        margin: "2.75rem 0 0"
    },
    ".off-canvas ul li": {
        padding: "1rem 0",
        borderBottom: "1px solid rgba(204, 204, 204, .2)"
    },
    ".off-canvas ul li a": {
        fontWeight: "600",
        color: "#0f2765"
    },
    ".off-canvas ul li:last-child": {
        borderBottom: "none"
    },
    ".off-canvas ul a": {
        color: "#fff"
    },
    ".off-canvas .close-button": {
        fontWeight: "600",
        fontSize: "2rem",
        color: "#0c1b40",
        position: "absolute",
        top: "12px",
        right: "12px"
    },
    ".off-canvas--open": {
        left: "0"
    },
    "ol, ul": {
        listStyle: "none"
    },
    "blockquote": {
        quotes: "none"
    },
    "blockquote:after, blockquote:before": {
        content: "''",
    },
    ".container": {
        width: "1260px",
        margin: "0 auto"
    },
    "@media screen and (max-width:1439px)": {
        ".container": {
            width: "100%",
            boxSizing: "border-box",
            padding: "0 3rem"
        }
    },
    ".flexbox": {
        display: "flex",
        marginLeft: "-.625rem",
        marginRight: "-.625rem",
        rowGap: "1.25rem"
    },
    ".form-group, .grid": {
        display: "grid"
    },
    ".flexbox.flexbox--justify-center": {
        alignItems: "center",
        justifyContent: "center"
    },
    "input[type='text'], input[type='email'], input[type='password']": {
        display: "block",
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        color: "#333",
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginBottom: "10px"
    },
    ".hp-header": {
        display: "block",
        position: "fixed",
        zIndex: "1000",
        top: "0",
        left: "0",
        right: "0",
        width: "100%",
        padding: "0 2.5%",
        transitionDuration: "200ms",
        backgroundColor: "#fff",
        boxShadow: "0 0 10px rgba(0,0,0,.1)"
    },
    ".hp-header a": {
        display: "inline-block",
        fontSize: "15px",
        color: "#000",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "500",
        lineHeight: "45px",
        padding: "0 15px",
        transitionDuration: "300ms"
    },
    ".hp-header a:hover": {
        color: "#f50057"
    },
    ".hp-header .hp-logo": {
        display: "inline-block",
        fontSize: "24px",
        color: "#f50057",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        lineHeight: "45px",
        padding: "0 15px"
    },
    ".hp-header--scrolled": {
        backgroundColor: "#fff",
        boxShadow: "0 0 10px rgba(0,0,0,.1)"
    },
    ".hp-header--scrolled a": {
        color: "#000"
    },
    ".hp-header--scrolled .hp-logo": {
        color: "#f50057"
    },
    ".hp-hero": {
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center"
    },
    ".hp-hero:after": {
        content: "''",
        display: "block",
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(255, 0, 85, .8)"
    },
    ".hp-hero .hp-hero-content": {
        position: "relative",
        zIndex: "2",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "0 15px",
        color: "#fff"
    },
    ".hp-hero h1": {
        fontSize: "36px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "700",
        letterSpacing: "2px",
        marginBottom: "16px",
        lineHeight: "1.2"
    },
    ".hp-hero h2": {
        fontSize: "20px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        letterSpacing: "1px",
        textTransform: "uppercase"
    },
    ".hp-hero .btn": {
        display: "inline-block",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "500",
        letterSpacing: "1px",
        textTransform: "uppercase",
        padding: "14px 34px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        marginTop: "24px",
        transitionDuration: "300ms"
    },
    ".hp-hero .btn:hover": {
        backgroundColor: "#f50057",
        color: "#fff"
    },
    ".hp-services": {
        backgroundColor: "#f5f5f5",
        padding: "100px 0"
    },
    ".hp-services .section-title": {
        fontSize: "36px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "700",
        letterSpacing: "2px",
        textAlign: "center",
        marginBottom: "64px",
        color: "#333"
    },
    ".hp-services .services-item": {
        display: "block",
        textAlign: "center",
        marginBottom: "32px",
        color: "#333",
        textDecoration: "none"
    },
    ".hp-services .services-item:hover .services-item-hidden": {
        display: "block"
    },
    ".hp-services .services-item .services-item-hidden": {
        display: "none",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        letterSpacing: "1px",
        lineHeight: "1.5",
        marginTop: "16px"
    },
    ".hp-services .services-item .services-item-hidden p": {
        marginBottom: "0"
    },
    ".hp-services .services-item .services-item-hidden span": {
        display: "block",
        marginTop: "8px"
    },
    ".hp-testimonials": {
        backgroundColor: "#fff",
        padding: "100px 0"
    },
    ".hp-testimonials .section-title": {
        fontSize: "36px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "700",
        letterSpacing: "2px",
        textAlign: "center",
        marginBottom: "64px",
        color: "#333"
    },
    ".hp-testimonials .testimonial": {
        display: "block",
        textAlign: "center",
        marginBottom: "32px",
        padding: "0 30px"
    },
    ".hp-testimonials .testimonial img": {
        display: "block",
        margin: "0 auto",
        borderRadius: "50%",
        marginBottom: "16px"
    },
    ".hp-testimonials .testimonial h3": {
        fontSize: "20px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "700",
        letterSpacing: "1px",
        color: "#333"
    },
    ".hp-testimonials .testimonial p": {
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        letterSpacing: "1px",
        lineHeight: "1.5",
        color: "#666"
    },
    ".hp-testimonials-show-more .btn": {
        display: "block",
        margin: "0 auto",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "500",
        letterSpacing: "1px",
        padding: "14px 34px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        marginTop: "32px",
        color: "#fff",
        backgroundColor: "#f50057",
        transitionDuration: "300ms"
    },
    ".hp-testimonials-show-more .btn:hover": {
        backgroundColor: "#333"
    },
    ".hp-contact": {
        backgroundColor: "#f5f5f5",
        padding: "100px 0"
    },
    ".hp-contact .section-title": {
        fontSize: "36px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "700",
        letterSpacing: "2px",
        textAlign: "center",
        marginBottom: "64px",
        color: "#333"
    },
    ".hp-contact .contact-form": {
        maxWidth: "550px",
        margin: "0 auto"
    },
    ".hp-contact .contact-form input[type='text'], .hp-contact .contact-form input[type='email'], .hp-contact .contact-form input[type='password']": {
        display: "block",
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        color: "#333",
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginBottom: "10px"
    },
    ".hp-contact .contact-form textarea": {
        display: "block",
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        color: "#333",
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginBottom: "16px",
        resize: "none"
    },
    ".hp-contact .contact-form .btn": {
        display: "block",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "500",
        letterSpacing: "1px",
        padding: "14px 34px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        marginTop: "16px",
        color: "#fff",
        backgroundColor: "#f50057",
        transitionDuration: "300ms"
    },
    ".hp-contact .contact-form .btn:hover": {
        backgroundColor: "#333"
    },
    ".hp-footer": {
        backgroundColor: "#0c1b40",
        padding: "80px 0",
        color: "#fff"
    },
    ".hp-footer .hp-footer-logo": {
        fontSize: "32px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "700",
        letterSpacing: "2px",
        marginBottom: "32px"
    },
    ".hp-footer .footer-links-list": {
        marginBottom: "40px"
    },
    ".hp-footer .footer-links-list a": {
        display: "block",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        letterSpacing: "1px",
        color: "#ccc",
        marginBottom: "10px"
    },
    ".hp-footer .footer-links-list a:hover": {
        color: "#fff"
    },
    ".hp-footer .footer-copyright": {
        borderTop: "1px solid rgba(255, 255, 255, .2)",
        paddingTop: "24px"
    },
    ".hp-footer .footer-copyright p": {
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "300",
        letterSpacing: "1px",
        color: "rgba(255, 255, 255, .6)"
    },
    ".hp-footer .footer-social-icons": {
        marginTop: "24px"
    },
    ".hp-footer .footer-social-icons a": {
        display: "inline-block",
        fontSize: "18px",
        color: "#fff",
        margin: "0 12px"
    },
    "@media screen and (max-width:1024px)": {
        ".hp-hero h1": {
            fontSize: "30px"
        },
        ".hp-hero h2": {
            fontSize: "18px"
        },
        ".hp-hero .btn": {
            fontSize: "14px"
        },
        ".hp-services .section-title": {
            fontSize: "30px"
        },
        ".hp-services .services-item:hover .services-item-hidden": {
            display: "block"
        },
        ".hp-testimonials .section-title": {
            fontSize: "30px"
        },
        ".hp-testimonials .testimonial h3": {
            fontSize: "18px"
        },
        ".hp-testimonials .testimonial p": {
            fontSize: "14px"
        },
        ".hp-testimonials-show-more .btn": {
            fontSize: "14px"
        },
        ".hp-contact .section-title": {
            fontSize: "30px"
        },
        ".hp-contact .contact-form input[type='text'], .hp-contact .contact-form input[type='email'], .hp-contact .contact-form input[type='password']": {
            fontSize: "14px"
        },
        ".hp-contact .contact-form textarea": {
            fontSize: "14px"
        },
        ".hp-contact .contact-form .btn": {
            fontSize: "14px"
        }
    },
    "@media screen and (max-width:768px)": {
        ".container": {
            padding: "0 2rem"
        },
        ".hp-hero h1": {
            fontSize: "24px"
        },
        ".hp-hero h2": {
            fontSize: "16px"
        },
        ".hp-hero .btn": {
            fontSize: "12px"
        },
        ".hp-services .section-title": {
            fontSize: "24px",
            marginBottom: "48px"
        },
        ".hp-services .services-item": {
            marginBottom: "24px"
        },
        ".hp-services .services-item .services-item-hidden": {
            fontSize: "12px",
            marginTop: "12px"
        },
        ".hp-testimonials .section-title": {
            fontSize: "24px",
            marginBottom: "48px"
        },
        ".hp-testimonials .testimonial h3": {
            fontSize: "18px"
        },
        ".hp-testimonials .testimonial p": {
            fontSize: "14px"
        },
        ".hp-testimonials-show-more .btn": {
            fontSize: "12px"
        },
        ".hp-contact .section-title": {
            fontSize: "24px",
            marginBottom: "48px"
        },
        ".hp-contact .contact-form input[type='text'], .hp-contact .contact-form input[type='email'], .hp-contact .contact-form input[type='password']": {
            fontSize: "14px"
        },
        ".hp-contact .contact-form textarea": {
            fontSize: "14px"
        },
        ".hp-contact .contact-form .btn": {
            fontSize: "12px"
        },
        ".sticky-header__nav": {
            display: "block",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "-100%",
            width: "250px",
            backgroundColor: "#fff",
            boxShadow: "0 0 10px rgba(0,0,0,.1)",
            zIndex: "999",
            transition: "left .4s linear"
        },
        ".sticky-header__nav--open": {
            left: "0"
        },
        ".sticky-header__nav ul": {
            display: "block",
            margin: "2.75rem 0 0"
        },
        ".sticky-header__nav ul li": {
            margin: "0",
            padding: "0"
        },
        ".sticky-header__nav ul li a": {
            display: "block",
            fontSize: "16px",
            color: "#000",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
            lineHeight: "45px",
            padding: "0 15px",
            transitionDuration: "300ms"
        },
        ".sticky-header__nav ul li a:hover": {
            color: "#f50057"
        }
    },
    "@media screen and (max-width:576px)": {
        ".container": {
            padding: "0 1.5rem"
        },
        ".hp-hero h1": {
            fontSize: "20px",
            marginBottom: "12px"
        },
        ".hp-hero h2": {
            fontSize: "14px"
        },
        ".hp-hero .btn": {
            fontSize: "10px",
            padding: "10px 20px"
        },
        ".hp-services .section-title": {
            fontSize: "20px",
            marginBottom: "32px"
        },
        ".hp-services .services-item .services-item-hidden": {
            fontSize: "10px",
            marginTop: "10px"
        },
        ".hp-testimonials .section-title": {
            fontSize: "20px",
            marginBottom: "32px"
        },
        ".hp-testimonials .testimonial h3": {
            fontSize: "16px"
        },
        ".hp-testimonials .testimonial p": {
            fontSize: "12px"
        },
        ".hp-testimonials-show-more .btn": {
            fontSize: "10px",
            padding: "10px 20px"
        },
        ".hp-contact .section-title": {
            fontSize: "20px",
            marginBottom: "32px"
        },
        ".hp-contact .contact-form input[type='text'], .hp-contact .contact-form input[type='email'], .hp-contact .contact-form input[type='password']": {
            fontSize: "12px"
        },
        ".hp-contact .contact-form textarea": {
            fontSize: "12px"
        },
        ".hp-contact .contact-form .btn": {
            fontSize: "10px",
            padding: "10px 20px"
        }
    },
    "@media screen and (max-width:375px)": {
        ".hp-hero h1": {
            fontSize: "18px",
            marginBottom: "10px"
        },
        ".hp-hero h2": {
            fontSize: "12px"
        },
        ".hp-hero .btn": {
            fontSize: "8px",
            padding: "8px 16px"
        },
        ".hp-services .section-title": {
            fontSize: "18px",
            marginBottom: "24px"
        },
        ".hp-services .services-item .services-item-hidden": {
            fontSize: "10px",
            marginTop: "8px"
        },
        ".hp-testimonials .section-title": {
            fontSize: "18px",
            marginBottom: "24px"
        },
        ".hp-testimonials .testimonial h3": {
            fontSize: "14px"
        },
        ".hp-testimonials .testimonial p": {
            fontSize: "10px"
        },
        ".hp-testimonials-show-more .btn": {
            fontSize: "8px",
            padding: "8px 16px"
        },
        ".hp-contact .section-title": {
            fontSize: "18px",
            marginBottom: "24px"
        },
        ".hp-contact .contact-form input[type='text'], .hp-contact .contact-form input[type='email'], .hp-contact .contact-form input[type='password']": {
            fontSize: "10px"
        },
        ".hp-contact .contact-form textarea": {
            fontSize: "10px"
        },
        ".hp-contact .contact-form .btn": {
            fontSize: "8px",
            padding: "8px 16px"
        }
    },
    ".sticky-header": {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        zIndex: "1000",
        transition: "background-color .3s"
    },
    ".sticky-header--scrolled": {
        backgroundColor: "#fff",
        boxShadow: "0 0 10px rgba(0,0,0,.1)"
    },
    ".sticky-header__content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".625rem 2.5%",
        height: "64px"
    },
    ".sticky-header__logo": {
        display: "inline-block",
        fontSize: "24px",
        color: "#f50057",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        lineHeight: "45px",
        padding: "0 15px"
    },
    ".sticky-header__nav": {
        display: "none"
    },
    ".sticky-header__nav ul": {
        listStyleType: "none",
        display: "flex",
        margin: "0",
        padding: "0"
    },
    ".sticky-header__nav ul li": {
        margin: "0",
        padding: "0"
    },
    ".sticky-header__nav ul li a": {
        display: "inline-block",
        fontSize: "15px",
        color: "#000",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "500",
        lineHeight: "45px",
        padding: "0 15px",
        transitionDuration: "300ms"
    },
    ".sticky-header__nav ul li a:hover": {
        color: "#f50057"
    },
};