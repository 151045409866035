import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component'

import ComponentOpenerBg from './../../../ComponentOpenerBg';
const HomeServices = loadable(() => import('./_parts/HomeServices'));
const HomeAbout = loadable(() => import('./_parts/HomeAbout'));
const HomeTestimonials = loadable(() => import('./_parts/HomeTestimonials'));
import { SCROLL_TARGETS, scrollToElement } from "../../utils/ScrollUtils";
import { SEO } from "../../utils/SeoUtils";

const Home = ({ isHomePage }) => {
    useEffect(() => {
        new ComponentOpenerBg();
    }, []);

    return (
        <React.Fragment>
            <SEO
                title='T&K - Účetní a daňová kancelář'
                description='Profesionální služby v oblasti účetnictví a daní. Podvojné účetnitcví, daňová evidence, mzdová a personální agenda, účetní poradenství.'
                keywords='Podvojné účetnictví, daňová evidence, přiznání daně, zpracování mzdové agendy, účetní poradenství'
                type='article'
            />
            <section className="hp-opener">
                <div className="hp-opener-bg" data-opener-bg="./assets/images/styles/home/hero-bg.jpg"></div>

                <div className="hp-opener-content">
                    <h1 className="title title--hp-opener">
                        Účetní a daňová kancelář
                        <span>T&K s.r.o.</span>
                    </h1>
                    <div className="hp-opener-buttons">
                        <Link to={'/nase-sluzby'}
                              className="btn btn--blue--full"
                              onClick={(event) => scrollToElement(`${SCROLL_TARGETS.SERVICES}`)}
                        >
                            Naše služby
                        </Link>
                        <Link to={'/'}
                              className="btn btn--yellow"
                              onClick={(event) => scrollToElement(`${SCROLL_TARGETS.CONTACT}`)}
                        >
                            Napište nám
                        </Link>
                    </div>
                </div>
            </section>
            <HomeServices />
            <HomeAbout />
            <HomeTestimonials />
        </React.Fragment>
    );
}

export default Home;