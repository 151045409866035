import React, { Component } from 'react';
import { scroller, Element } from 'react-scroll';
import { SCROLL_TARGETS } from '../utils/ScrollUtils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const Contact = () => {
    return (
        <section name={SCROLL_TARGETS.CONTACT} className="section section--hp hp-contact" data-scroll-target="contact">
            <div className="hp-contact-brno hp-contact-bg">
                <div className="container">
                    <div className="hp-contact-wrapper">
                        <div>
                            <div id="office-brno" className="contact-office">
                                <h2 className="title title--section">
                                    KANCELÁŘ BRNO
                                </h2>

                                <div className="contact-office-content">
                                    <div className="contact-office-address">
                                        <LocationOnIcon />
                                        <address>
                                            <p>Cejl 29/76,</p>
                                            <p>602 00 Brno - Zábrdovice</p>
                                        </address>
                                    </div>

                                    <a className="hp-contact-link"
                                       target="_blank"
                                       title={"zobrazit mapu"}
                                       href="https://www.google.com/maps/search/t%26k/@49.2011356,16.6259741,18.75z">
                                        zobrazit mapu
                                    </a>

                                    <ul className="contact-office-list">
                                        <li>
                                            <strong>Ing. Filip Tomaštík</strong>
                                        </li>
                                        <li className="office-list-contact">
                                            <PhoneIcon />
                                            <a href="tel://+420 603 807 978">
                                                +420 603 807 978
                                            </a>
                                        </li>
                                        <li className="office-list-contact">
                                            <AlternateEmailIcon />
                                            <a href="mailto:tomastik@tk-ucetnictvi.cz">
                                                tomastik@tk-ucetnictvi.cz
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div id="office-sternberk" className="contact-office">
                                <h2 className="title title--section">
                                    KANCELÁŘ ŠTERNBERK
                                </h2>

                                <div className="contact-office-content">
                                    <div className="contact-office-address">
                                        <LocationOnIcon />
                                        <address>
                                            <p>Hlavní náměstí 15,</p>
                                            <p>785 01 Šternberk</p>
                                        </address>
                                    </div>

                                    <a className="hp-contact-link"
                                       target="_blank"
                                       title={"zobrazit mapu"}
                                       href="https://goo.gl/maps/9QvF5xqk5aWzhzrg9">
                                        zobrazit mapu
                                    </a>

                                    <ul className="contact-office-list">
                                        <li>
                                            <strong>Ing. Martin Koza</strong>
                                        </li>
                                        <li className="office-list-contact">
                                            <PhoneIcon />
                                            <a href="tel://+420 732 259 642">
                                                +420 732 259 642
                                            </a>
                                        </li>
                                        <li className="office-list-contact">
                                            <AlternateEmailIcon />
                                            <a href="mailto:koza@tk-ucetnictvi.cz">
                                                koza@tk-ucetnictvi.cz
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;