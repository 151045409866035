import React, { Component } from 'react'
import Layout from './components/Layout';
//import CookieConsent from "./components/CookieConsent";
class App extends Component {
    render () {
        return (
            <>
                {/*<CookieConsent />*/}
                <Layout />
            </>
        );
    }
}
export default App;
