import React, { useEffect, useState } from 'react';
import ComponentOpenerBg from "../../../ComponentOpenerBg";
import ApiClient from "../../api/ApiClient";
import HomeTestimonials from '../Home/_parts/HomeTestimonials';
import { SEO } from "../../utils/SeoUtils";
import LoadingFeature from "../../components/LoadingFeature";

const Partners = () => {
    const apiClient = new ApiClient();
    const [partners, setPartners] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        new ComponentOpenerBg();
        reloadData();
    }, []);

    const reloadData = () => {
        apiClient.findPartners()
            .then(partners => {
                setPartners(partners);
                setIsLoading(false);
                // console.log('reload', testimonials);
            })
            .catch(error => {
                console.error("Error fetching testimonials:", error);
                setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <SEO
                title='Reference | T&K - Účetní a daňová kancelář'
                description='Profesionální služby v oblasti účetnictví a daní. Podvojné účetnitcví, daňová evidence, mzdová a personální agenda, účetní poradenství.'
                keywords='Podvojné účetnictví, daňová evidence, přiznání daně, zpracování mzdové agendy, účetní poradenství'
                type='article'
            />
            <section className={'sp-intro'}>
                <div className="sp-intro__bg" data-opener-bg="./assets/images/styles/home/hero-bg.jpg"></div>
            </section>
            <section className={'s-content s-content--sp'}>
                <div className="container">
                    <h1 className={'title title--sp-border'}>Reference</h1>
                </div>

                {!isLoading && partners.length> 0 ? (
                <React.Fragment>
                <div className="container mt-4">
                    <div className={'partners__wrapper'}>
                        {partners.map(partner => (
                            <article key={partner.id} className="partners-item" data-partner-id={`${partner.id}`}>
                                {partner.content.map((contentItem, index) => (
                                    <div key={index} className="partners-item__content">
                                        <div className="partners-item__image">
                                            <picture>
                                                <source srcSet={`./files/partners/${partner.category}/${partner.id}/${partner.img}`} type="image/webp" />
                                                <source srcSet={`./files/partners/${partner.category}/${partner.id}/${partner.img}`} type="image/png" />
                                                <img src={`./files/partners/${partner.category}/${partner.id}/${partner.img}`} alt={contentItem.title} loading={'lazy'} />
                                            </picture>
                                        </div>

                                        <h3 className="partners-item__title">
                                            {contentItem.title}
                                        </h3>

                                        <div className="partners-item__description">
                                            {contentItem.description}
                                        </div>
                                    </div>
                                ))}
                            </article>
                        ))}
                    </div>
                </div>
                </React.Fragment>
                ) : (
                    <div className="container mt-4">
                        <LoadingFeature />
                    </div>
                )}
            </section>

            <HomeTestimonials noTitle={true} noShowMore={true} />
        </React.Fragment>
    );
};

export default Partners;
