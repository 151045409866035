import React, { Component, useEffect, useState } from 'react';
import ComponentOpenerBg from "../../../ComponentOpenerBg";
import ApiClient from "../../api/ApiClient";
import ServiceSidebar from "./ServiceSidebar";
import ServicesForm from "../../components/ServicesForm";
import { useParams } from 'react-router-dom';
import {SEO} from "../../utils/SeoUtils";
import LoadingFeature from "../../components/LoadingFeature";
import NotFound from "../NotFound";

const ServiceDetail = () => {
    const { serviceSlug } = useParams();
    const apiClient = new ApiClient();
    //console.log(serviceSlug);
    const [serviceContent, setServiceContent] = useState();
    const [statusCode, setStatusCode] = useState(200);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.body.classList.add('page--service-detail');
        //console.log('Reloading data for:', serviceSlug);
        reloadData();

        return () => {
            document.body.classList.remove('page--service-detail');
        };
    }, [serviceSlug]);

    const reloadData = () => {
        apiClient.findServiceBySlug(serviceSlug)
            .then(serviceContent => {
                setServiceContent(serviceContent);
                setStatusCode(200);
                setIsLoading(false);
                //console.log('reload', serviceContent);
            })
            .catch(error => {
                console.error("Error fetching service:", error);
                setStatusCode(error.response ? error.response.status : 404);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        new ComponentOpenerBg();
    }, []);

    return (
        <React.Fragment>
            {statusCode !== 200 ? (
                <NotFound />
            ) : (
                serviceContent && (
                    <React.Fragment>
                        <SEO
                            title={`${serviceContent.metaTitle} | T&K - Účetní a daňová kancelář`}
                            description={`${serviceContent.metaDesc}`}
                            keywords={`${serviceContent.metaKeywords}`}
                            type='article'
                        />
                        <section className={'sp-intro'}>
                            <div className="sp-intro__bg" style={{background: 'url(./assets/images/styles/home/hero-bg.jpg)'}} />
                        </section>
                        {!isLoading && serviceContent.content.length > 0 ? (
                            <React.Fragment>
                                {serviceContent.content.map((item, index) => (
                                    <section key={index} className={'s-content s-content--sp'}>
                                        <div className="container">
                                            <h1 className={'title title--sp-border'}>{item.title}</h1>
                                        </div>
                                        <div className="container container-service mt-4">
                                            <div className={'grid grid--70-30'}>
                                                <div className={'sp-content'} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                <div>
                                                    <ServiceSidebar />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ))}

                                <ServicesForm />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <section className={'s-content s-content--sp'}>
                                    <div className="container">
                                        {isLoading ? <LoadingFeature /> : null}
                                    </div>
                                </section>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )
            )}
        </React.Fragment>
    );
};

export default ServiceDetail;