import Constants from "./Constants";

class ComponentOpenerBg {

    constructor() {
        this.openerBgs = document.querySelectorAll("[data-opener-bg]");
        //this.openerProduct = document.querySelector("[data-opener-product]");

        if (this.openerBgs){
            this.initOpenerBg();
        }
        /*if(this.openerProduct){
            this.openerProductBg();
        }*/
    }

    initOpenerBg() {
        //set opener bg image by data attribute "opener-bg"

        let openerBg = this.openerBgs;

        for (let i = 0; i < openerBg.length; i++) {
            let el = openerBg[i];
            let bgUrl = el.dataset.openerBg;
            let webpUrl = bgUrl.replace(/\.[^/.]+$/, ".webp"); //odstrani koncovku a prida .webp
            //console.log(bgUrl, webpUrl);
            //let bgEl = openerBg[i].querySelector(".articles-bg");

            el.style.cssText += "background: url("+bgUrl+") no-repeat cover; background-image: -webkit-image-set(url("+webpUrl+") 1x);";

            //el.style.backgroundImage = "url("+bgUrl+")";
            //el.style.backgroundImage += "-webkit-image-set(url("+webpUrl+") 1x)";
        }
    }

} export default ComponentOpenerBg;
