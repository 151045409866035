import React, { useEffect, useState } from 'react';
import ComponentTestimonialsSlider from "../../../../ComponentTestimonialsSlider";
import ApiClient from './../../../api/ApiClient';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoadingFeature from "../../../components/LoadingFeature";

const HomeTestimonials = ({ noTitle, noShowMore }) => {
    const apiClient = new ApiClient();
    const [testimonials, setTestimonials] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = () => {
        apiClient.findTestimonials()
            .then(testimonials => {
                setTestimonials(testimonials);
                setIsLoading(false);
                //console.log('reload', testimonials);
            })
            .catch(error => {
                console.error("Error fetching testimonials:", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        new ComponentTestimonialsSlider();
    }, [testimonials]);

    return (
        <section className="section section--hp hp-testimonials">
            {!noTitle && (
                <div className="section-header">
                    <div className="container">
                        <h2 className="title title--section">
                            REFERENCE
                        </h2>
                    </div>
                </div>
            )}

            {!isLoading && testimonials.length> 0 ? (
                <React.Fragment>
                    <div className="hp-testimonials-slider">
                        <div className="container">
                            <div className="testimonials-slider-controls" role="navigation">
                                <div className="testimonials-slider-prev" data-slider-prev>
                                    <NavigateBeforeIcon />
                                </div>
                                <div className="testimonials-slider-next" data-slider-next>
                                    <NavigateNextIcon />
                                </div>
                            </div>

                            <div className="testimonials-slider-indicators">
                                <ol data-indicators-list></ol>
                            </div>

                            <div className="testimonials-slider-inner">
                                {testimonials.map(testimonial => (
                                    <div key={testimonial.id} className="testimonials-slide">
                                        {testimonial.category === 2 ? (
                                            <div className="testimonials-slide-wrap testimonials-slide-wrap--no-img">
                                                <div className="testimonials-slide-content">
                                                    {testimonial.content.map((contentItem, index) => (
                                                        <div key={index} className="content-item">
                                                            <blockquote>{contentItem.description}</blockquote>
                                                            <div className="testimonials-slide-author">
                                                                <strong>{contentItem.title}</strong>
                                                                {testimonial.company}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="testimonials-slide-wrap">
                                                <div className="testimonials-slide-header text text--center">
                                                    {testimonial.content.map((contentItem, index) => (
                                                        <picture key={index}>
                                                            <source srcSet={`./files/testimonials/${testimonial.category}/${testimonial.id}/${testimonial.img}`} type="image/webp" />
                                                            <source srcSet={`./files/testimonials/${testimonial.category}/${testimonial.id}/${testimonial.img}`} type="image/png" />
                                                            <img
                                                                className="testimonials-slide__image"
                                                                src={`./files/testimonials/${testimonial.category}/${testimonial.id}/${testimonial.img}`}
                                                                width={`${testimonial.imgWidth}px`}
                                                                height={`${testimonial.imgHeight}px`}
                                                                alt={contentItem.title}
                                                                loading={'lazy'}
                                                            />
                                                        </picture>
                                                    ))}
                                                </div>
                                                <div className="testimonials-slide-content">
                                                    {testimonial.content.map((contentItem, index) => (
                                                        <div key={index} className="content-item">
                                                            <blockquote>{contentItem.description}</blockquote>
                                                            <div className="testimonials-slide-author">
                                                                <strong>{contentItem.title}</strong>
                                                                {testimonial.company}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <LoadingFeature />
            )}

            <div className={'container hp-testimonials-show-more'}>
                {!noShowMore && (
                    <Link to={'/reference'} className={'btn btn--show-more'}>Zobrazit více</Link>
                )}
            </div>
        </section>
    );
};

export default HomeTestimonials;