import React, {useRef, useState} from 'react';
import ApiClient from "../api/ApiClient";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { SCROLL_TARGETS } from "../utils/ScrollUtils";

const ServicesForm = () => {
    const apiClient = new ApiClient();
    const formMessageRef = useRef(null);

    const [formData, setFormData] = useState({
        services_subject: '',
        services_name: '',
        services_email: '',
        services_tel: '',
        services_lawForm: '',
        services_business: '',
        services_dph: '',
        services_income: '',
        services_inventoryCards: '',
        services_employees: '',
        services_totalDocs: '',
        services_message: '',
    });

    const [formSuccess, setFormSuccess] = useState(null);
    const [formError, setFormError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (e, name = undefined, value = null) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        //usage example onChange={(e, newValue) => handleSelectChange(e, 'services_subject', newValue)}
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await apiClient.sendServicesFormData(formData);
            if (response.status.toLowerCase() === 'success') {
                setFormSuccess('Vaše zpráva byla úspěšně odeslána.');
                setFormError(null);
                scrollToFormMessage();
            } else {
                setFormSuccess(null);
                setFormError('Odeslání se nezdařilo.');
                scrollToFormMessage();
            }
        } catch (error) {
            console.error('API error:', error);
            setFormSuccess(null);
            setFormError('Odeslání se nezdařilo.');
            scrollToFormMessage();
        }
    };

    const validateForm = () => {
        const {
            services_subject,
            services_name,
            services_email,
            services_tel,
            services_business,
            services_income,
            services_inventoryCards,
            services_employees,
            services_totalDocs,
            services_message,
        } = formData;

        if (
            !services_name ||
            !services_email ||
            !services_tel ||
            !services_business ||
            !services_income ||
            !services_inventoryCards ||
            !services_employees ||
            !services_totalDocs ||
            !services_message
        ) {
            setFormError('Všechna pole jsou povinná.');
            return false;
        }

        const phoneRegex = /^[0-9+]+$/;
        if (!phoneRegex.test(services_tel)) {
            setFormError('Telefonní číslo může obsahovat pouze čísla a +.');
            return false;
        }

        return true;
    };

    const scrollToFormMessage = () => {
        window.scrollTo({
            top: formMessageRef.current.offsetTop - 120,
            behavior: 'smooth',
        });
    };

    return (
        <section className={'section services-offer-form'} name={SCROLL_TARGETS.SERVICES_FORM}>
            <div className="section-header text text--center">
                <div className="container">
                    <h2 className="title title--section">
                        NEZÁVAZNÁ CENOVÁ NABÍDKA NA MÍRU
                    </h2>
                </div>
            </div>

            <div className="container mt-4">
                <div className="grid grid--100">
                    <div ref={formMessageRef}>
                        {formSuccess !== null ? (
                            <div ref={formMessageRef} className="alert alert--success" role="alert">
                                {formSuccess}
                            </div>
                        ) : formError !== null ? (
                            <div ref={formMessageRef} className="alert alert--danger" role="alert">
                                {formError}
                            </div>
                        ) : null}
                    </div>
                </div>

                <form name="servicesForm" className={'form-wrapper'} onSubmit={handleSubmit}>
                    <div className="grid grid--100 grid--cg-12">
                        <div>
                            <div className="form-group">
                                <FormControl variant="outlined">
                                    <InputLabel id="subject-label">Mám zájem o</InputLabel>
                                    <Select
                                        labelId="subject-label"
                                        id="subject"
                                        name="services_subject"
                                        value={formData.services_subject}
                                        label="Mám zájem o"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="1">Podvojné účetnictví</MenuItem>
                                        <MenuItem value="2">Daňová evidence</MenuItem>
                                        <MenuItem value="3">Mzdová a personální agenda</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid--33 grid--cg-12">
                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_name"
                                    id="name"
                                    label="Jméno a příjmení"
                                    variant="outlined"
                                    value={formData.services_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_email"
                                    id="email"
                                    label="Váš email"
                                    type={'email'}
                                    variant="outlined"
                                    value={formData.services_email}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_tel"
                                    id="phone"
                                    label="Váš telefon"
                                    variant="outlined"
                                    value={formData.services_tel}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid--33 grid--cg-12">
                        <div>
                            <div className="form-group">
                                <FormControl variant="outlined">
                                    <InputLabel id="lawForm-label">Právní forma</InputLabel>
                                    <Select
                                        labelId="lawForm-label"
                                        id="lawForm"
                                        name="services_lawForm"
                                        value={formData.services_lawForm}
                                        label="Právní forma"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Právnická osoba">Právnická osoba</MenuItem>
                                        <MenuItem value="Fyzická osoba - OSVČ">Fyzická osoba - OSVČ</MenuItem>
                                        <MenuItem value="Jiné">Jiné</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_business"
                                    id="business"
                                    label="Předmět podnikání"
                                    variant="outlined"
                                    value={formData.services_business}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <FormControl variant="outlined">
                                    <InputLabel id="dph-label">Jste plátcem DPH?</InputLabel>
                                    <Select
                                        labelId="dph-label"
                                        id="dph"
                                        name="services_dph"
                                        value={formData.services_dph}
                                        label="Jste plátcem DPH?"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Ano, měsíční">Ano, měsíční</MenuItem>
                                        <MenuItem value="Ano, čtvrtletní">Ano, čtvrtletní</MenuItem>
                                        <MenuItem value="Ne">Ne</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className={'grid grid--25 grid--cg-12'}>
                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_income"
                                    id="income"
                                    label="Roční obrat (Kč)"
                                    variant="outlined"
                                    type={'number'}
                                    value={formData.services_income}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_inventoryCards"
                                    id="invCards"
                                    type={'number'}
                                    label="Počet inventárních karet"
                                    variant="outlined"
                                    value={formData.services_inventoryCards}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <TextField
                                    name="services_employees"
                                    id="employees"
                                    type={'number'}
                                    label="Počet zaměstnanců"
                                    variant="outlined"
                                    value={formData.services_employees}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group">
                                <Tooltip title="Skládá se z vystavených a přijatých faktur, pokladních dokladů, počtu položek na bankovních výpisech.">
                                    <TextField
                                        name="services_totalDocs"
                                        id="totalDocs"
                                        type={'number'}
                                        label="Celkový počet dokladů"
                                        variant="outlined"
                                        value={formData.services_totalDocs}
                                        onChange={handleChange}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className={'grid grid--100'}>
                        <div>
                            <div className="form-group">
                                <TextField
                                    id="msg"
                                    name="services_message"
                                    label="Další informace, které by nás mohly zajímat"
                                    multiline
                                    value={formData.services_message}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid--50-50">
                        <div></div>
                        <div className="text text--right">
                            <button name="send" className="btn btn--yellow">
                                Chci nezávaznou cenovou nabídku
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ServicesForm;
