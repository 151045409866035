import React from 'react';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ServiceSidebar = () => {
    const navLinks = [
        { label: "Podvojné účetnictví", slug: "podvojne-ucetnictvi" },
        { label: "Daňová evidence", slug: "danova-evidence" },
        { label: "Mzdová a personální agenda", slug: "mzdove-ucetnictvi" },
        { label: "Poradenství", slug: "ucetni-a-danove-poradenstvi" },
    ];

    return (
        <React.Fragment>
            <div className={'sp-sidebar'}>
                <div className={'sidebar-title'}>Naše služby</div>
                <ul className={'sidebar-list'}>
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            <Link
                                className="sidebar-link"
                                to={{
                                    pathname: `/${link.slug}`,
                                    state: { detail: link.slug }
                                }}
                                title={link.label}
                            >
                                <ChevronRightIcon /> {link.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default ServiceSidebar;