import React, { useState, useRef } from 'react';
import ApiClient from "../api/ApiClient";

import TextField from '@mui/material/TextField';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

const ContactForm = () => {
    const apiClient = new ApiClient();
    const formMessageRef = useRef(null);

    const [formData, setFormData] = useState({
        contact_name: '',
        contact_email: '',
        contact_tel: '',
        contact_message: '',
    });

    const [formSuccess, setFormSuccess] = useState(null);
    const [formError, setFormError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await apiClient.sendContactFormData(formData);
            if (response.status.toLowerCase() === 'success') {
                setFormSuccess('Vaše zpráva byla úspěšně odeslána.');
                setFormError(null);
                scrollToFormMessage();
            } else {
                setFormSuccess(null);
                setFormError('Odeslání se nezdařilo.');
                scrollToFormMessage();
            }
        } catch (error) {
            console.error('API error:', error);
            setFormSuccess(null);
            setFormError('Odeslání se nezdařilo.');
            scrollToFormMessage();
        }
    };

    const validateForm = () => {
        const { contact_name, contact_email, contact_tel, contact_message } = formData;

        if (!contact_name || !contact_email || !contact_tel || !contact_message) {
            setFormError('Všechna pole jsou povinná.');
            return false;
        }

        const phoneRegex = /^[0-9+]+$/;
        if (!phoneRegex.test(contact_tel)) {
            setFormError('Telefonní číslo může obsahovat pouze čísla a +.');
            return false;
        }

        return true;
    };

    const scrollToFormMessage = () => {
        formMessageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <section className="section hp-contact-form">
            <div className="section-header text text--center">
                <div className="container">
                    <h2 className="title title--section">NEVÁHEJTE NÁS KONTAKTOVAT</h2>
                </div>
            </div>

            <div className="container mt-4">
                <div className="grid grid--100">
                    <div ref={formMessageRef}>
                        {formSuccess !== null ? (
                            <div ref={formMessageRef} className="alert alert--success" role="alert">
                                {formSuccess}
                            </div>
                        ) : formError !== null ? (
                            <div ref={formMessageRef} className="alert alert--danger" role="alert">
                                {formError}
                            </div>
                        ) : null}
                    </div>
                </div>

                <form name="contactForm" className={'form-wrapper'} onSubmit={handleSubmit}>
                    <div className="grid grid--33 grid--cg-12">
                        <div>
                            <div className="form-group mt-md-1">
                                <TextField
                                    id="name"
                                    name="contact_name"
                                    label="Vaše jméno"
                                    placeholder="Vaše jméno"
                                    variant="outlined"
                                    type="text"
                                    value={formData.contact_name}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <PersonOutlineOutlinedIcon
                                                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group mt-md-1">
                                <TextField
                                    id="email"
                                    name="contact_email"
                                    label="Váš email"
                                    placeholder="Váš email"
                                    variant="outlined"
                                    type="email"
                                    value={formData.contact_email}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <AlternateEmailOutlinedIcon
                                                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form-group mt-md-1">
                                <TextField
                                    id="tel"
                                    name="contact_tel"
                                    label="Váš telefon"
                                    placeholder="Váš telefon"
                                    variant="outlined"
                                    type="tel"
                                    value={formData.contact_tel}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <LocalPhoneOutlinedIcon
                                                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid--100">
                        <div>
                            <div className="form-group">
                                <TextField
                                    id="msg"
                                    name="contact_message"
                                    label="Vaše zpráva"
                                    placeholder="Vaše zpráva"
                                    multiline
                                    value={formData.contact_message}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <ChatBubbleOutlineOutlinedIcon
                                                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid--50-50 mt-1">
                        <div></div>
                        <div className="text text--right">
                            <button name="send" className="btn btn--yellow">
                                Odeslat
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ContactForm;
