import React, { useEffect, useState } from 'react';
import NavigationResponsive from "./NavigationResponsive";
import Navigation from "./Navigation";
import { SCROLL_TARGETS, scrollToElement } from '../utils/ScrollUtils';
import { InlineCss } from '../utils/InlineCss';

const Header = ({ isHomePage }) => {
    const [showStickyNav, setShowStickyNav] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const homePageStyles = {
        InlineCss
    };

    const handleScroll = () => {
        if (window.scrollY > 150) {
            setShowStickyNav(true);
        } else {
            setShowStickyNav(false);
        }
    };

    const checkWindowSize = () => {
        const mobile = window.innerWidth < 992;
        setIsMobile(mobile);
    };

    useEffect(() => {
        checkWindowSize();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', checkWindowSize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []);

    const contactInfo = {
        email: "info@tk-ucetnictvi.cz",
        phone: "+420 603 807 978",
        servicesForm: {
            link: "/cenik",
            scroll: SCROLL_TARGETS.SERVICES_FORM,
            onClick: (event) => {
                scrollToElement(`${SCROLL_TARGETS.SERVICES_FORM}`, 'cenik');
            },
        },
    };

    const navLinks = [
        { label: "Domů", link: "/" },
        {
            label: "Naše služby",
            link: "/nase-sluzby",
            scroll: SCROLL_TARGETS.SERVICES,
            onClick: (event) => {
                scrollToElement(`${SCROLL_TARGETS.SERVICES}`, 'home');
            },
        },
        { label: "Reference", link: "/reference" },
        { label: "Ceník", link: "/cenik" },
        {
            label: "O nás",
            link: "/o-nas",
            scroll: SCROLL_TARGETS.ABOUT,
            onClick: (event) => {
                scrollToElement(`${SCROLL_TARGETS.ABOUT}`, 'home');
            },
        },
        { label: "Kariéra", link: "/kariera" },
        {
            label: "Kontakt",
            link: "/kontakt",
            scroll: SCROLL_TARGETS.CONTACT,
            onClick: (event) => {
                event.preventDefault();
                scrollToElement(`${SCROLL_TARGETS.CONTACT}`);
            },
        },
        // ... další odkazy ...
    ];

    return (
        <React.Fragment>
            <div style={isHomePage ? homePageStyles : {}}>
            </div>

            <Navigation
                stickyNav={showStickyNav}
                navLinks={navLinks}
                contactInfo={contactInfo}
                isMobile={isMobile}
            />
            <NavigationResponsive
                navLinks={navLinks}
                contactInfo={contactInfo}
                isMobile={isMobile}
            />
        </React.Fragment>
    );
}

export default Header;