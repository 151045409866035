import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneIcon from '@mui/icons-material/Phone';

const NavigationRegular = ({ navLinks, contactInfo, onNavLinkClick }) =>  {
    return (
        <div className="header-wrapper">
            <div className="container">
                <div className="header-info">
                    <div className="header-info__logo">
                        <div className="header-logo">
                            <Link to="/" title="Účetní a daňová kancelář T&K s.r.o.">
                                <picture>
                                    <source srcSet="./assets/images/styles/logo.webp" type="image/webp" />
                                    <source srcSet="./assets/images/styles/logo.png" type="image/png" />
                                    <img src="./assets/images/styles/logo.png" alt="Logo" />
                                </picture>
                            </Link>
                        </div>
                    </div>
                    <div className="header-info__list">
                        <ul>
                            <li>
                                <PhoneIcon />
                                <div>
                                    <Link to={`tel://${contactInfo.phone}`} title={`Volat ${contactInfo.phone}`}>
                                        {contactInfo.phone}
                                    </Link>
                                    <span>Zavolejte nám</span>
                                </div>
                            </li>
                            <li>
                                <DraftsIcon />
                                <div>
                                    <Link to={`mailto:${contactInfo.email}`} title={`Email: ${contactInfo.email}`}>
                                        {contactInfo.email}
                                    </Link>
                                    <span>Máte dotaz?</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="header-navbar">
                <div className="container">
                    <nav className="navigation" role="navigation" aria-label="Main navigation" data-nav-navbar>
                        <ul className="nav-list">
                            {navLinks.map((link, index) => (
                                <li key={index}>
                                    {link.onClick ? (
                                        <NavLink
                                            className={`nav-link`}
                                            to={link.link}
                                            title={link.label}
                                            onClick={link.onClick}
                                        >
                                            {link.label}
                                        </NavLink>
                                    ) : (
                                        <NavLink className="nav-link" to={link.link} title={link.label}>
                                            {link.label}
                                        </NavLink>
                                    )}
                                </li>
                            ))}

                            <li className="nav-list-offer">
                                <NavLink
                                    className={`ref-services-form`}
                                    to={`${contactInfo.servicesForm.link}`}
                                    onClick={contactInfo.servicesForm.onClick}
                                    title="Kontaktujte nás"
                                >
                                    Kontaktujte nás
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default NavigationRegular;
