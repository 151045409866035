import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavigationOffCanvas from "./NavigationOffCanvas";

const NavigationResponsive = ({ isMobile, navLinks, contactInfo }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };

    if (!isMobile) {
        return null; // nevykresluj
    }

    return (
        <header className={`header header-responsive`}>
            <div className={'header-responsive__wrapper'}>
                <div className="header__logo">
                    <Link to="/">
                        <picture>
                            <source srcSet="./assets/images/styles/logo-white@sm.webp" type="image/webp"></source>
                            <source srcSet="./assets/images/styles/logo-white@sm.png" type="image/png"></source>
                            <img src="./assets/images/styles/logo-white@sm.png" width={'114px'} height={'52px'} alt="T&K" />
                        </picture>
                    </Link>
                </div>
                <div className="header__menu">
                    <div className="hamburger" onClick={toggleMenu}>
                        <span className={'hamburger__line'}></span>
                        <span className={'hamburger__line'}></span>
                        <span className={'hamburger__line'}></span>
                    </div>
                </div>
            </div>

            {isMenuOpen && (
                <NavigationOffCanvas
                    navLinks={navLinks}
                    contactInfo={contactInfo}
                    isOpen={isMenuOpen}
                    onClose={toggleMenu}
                />
            )}
        </header>
    );
};

export default NavigationResponsive;
