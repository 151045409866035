import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

const NavigationOffCanvas = ({ isOpen, onClose, navLinks, contactInfo }) => {

    if (!isOpen) {
        return null; // If it's not open, don't render
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const offCanvasElement = document.querySelector('.off-canvas');
            const hamburgerElement = document.querySelector('.hamburger');

            if (
                offCanvasElement &&
                !offCanvasElement.contains(event.target) &&
                hamburgerElement &&
                !hamburgerElement.contains(event.target)
            ) {
                onClose();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <div className={`off-canvas ${isOpen ? 'off-canvas--open' : ''}`}>
            <a className="close-button" onClick={onClose}>
                &times;
            </a>

            <nav className="nav-off-canvas" role="navigation" aria-label="Main navigation">
                <ul>
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            {link.onClick ? (
                                <Link
                                    className="nav-link"
                                    to={link.link}
                                    title={link.label}
                                    onClick={link.onClick}
                                >
                                    {link.label}
                                </Link>
                            ) : (
                                <NavLink className="nav-link" to={link.link} title={link.label}>{link.label}</NavLink>
                            )}
                        </li>
                    ))}
                    <li className="nav-list-offer">
                        <NavLink
                            className={`ref-services-form`}
                            to={`${contactInfo.servicesForm.link}`}
                            onClick={contactInfo.servicesForm.onClick}
                            title="Kontaktujte nás"
                        >
                            Kontaktujte nás
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default NavigationOffCanvas;
