import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';

const NavigationSticky = ({navLinks, contactInfo}) => {

    return (
        <div className="sticky-header-wrapper">
            <div className="sticky-header__logo">
                <Link to="/" title="Účetní a daňová kancelář T&K s.r.o.">
                    <picture>
                        <source srcSet="./assets/images/styles/logo-white@sm.webp" type="image/webp"></source>
                        <source srcSet="./assets/images/styles/logo-white@sm.png" type="image/png"></source>
                        <img src="./assets/images/styles/logo-white@sm.png" width={'114px'} height={'52px'} alt="T&K"></img>
                    </picture>
                </Link>
            </div>
            <div className="sticky-header__nav">
                <nav className="navigation navigation--sticky" role="navigation" aria-label="Main navigation">
                    <ul className="nav-list">
                        {navLinks.map((link, index) => (
                            <li key={index}>
                                {link.onClick ? (
                                    <NavLink
                                        className={`nav-link`}
                                        to={link.link}
                                        title={link.label}
                                        onClick={link.onClick}
                                    >
                                        {link.label}
                                    </NavLink>
                                ) : (
                                    <NavLink className="nav-link" to={link.link} title={link.label}>{link.label}</NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className="sticky-header__info">
                <div className="sticky-header__info-md">
                    <ul>
                        <li>
                            <NavLink
                                className={`ref-services-form`}
                                to={`${contactInfo.servicesForm.link}`}
                                onClick={contactInfo.servicesForm.onClick}
                                title="Cenová nabídka zdarma"
                            >
                                Cenová nabídka zdarma
                            </NavLink>
                        </li>
                        <li>
                            <Link to={`tel://${contactInfo.phone}`} title={`Volat ${contactInfo.phone}`}>{contactInfo.phone}</Link>
                        </li>
                        <li>
                            <Link to={`mailto:${contactInfo.email}`} title={`Email: ${contactInfo.email}`}>{contactInfo.email}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default NavigationSticky;