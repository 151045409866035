import 'regenerator-runtime/runtime';
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollToTop } from "./app/utils/ScrollUtils";
import { HelmetProvider } from 'react-helmet-async';

import App from "./app/App";

class ComponentReactApp {

    constructor() {
        this.appRoot = document.getElementById("app-root"); //document.getElementById("root");

        if(this.appRoot){
            this.initReactApp();
        }
    }

    initReactApp() {
        const rootElement = this.appRoot;
        const root = createRoot(rootElement);
        const helmetContext = {};

        root.render(
            <HelmetProvider context={helmetContext}>
                <Router>
                    <ScrollToTop />
                    <App />
                </Router>
            </HelmetProvider>
        );
    }

} export default ComponentReactApp;
