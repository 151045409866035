import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import NavigationSticky from "./NavigationSticky";
import NavigationRegular from "./NavigationRegular";

const Navigation = ({ stickyNav, isMobile, navLinks, contactInfo }) => {
    if (isMobile) {
        return null; // nevykresluj desktop pokud je mobil true
    }

    return (
        <header className={`header header--desktop ${stickyNav ? 'header--sticky' : ''}`}>
            {stickyNav ? (
                <NavigationSticky navLinks={navLinks} contactInfo={contactInfo} />
            ) : (
                <NavigationRegular navLinks={navLinks} contactInfo={contactInfo} />
            )}
        </header>
    );
}

export default Navigation;
