import request from "superagent";

export default class HttpClient {

    /**
     * Send http request by method GET
     * @param {string} url
     * @param {object} args
     * @returns {Promise}
     */
    sendGetRequest(url, args) {

        /*if (! "WebSocket" in window) { alert("WebSocket is supported by your Browser!"); }

        let ws = IPub.WebSockets.WAMP.initialize("ws://localhost:8800");

        ws.on('socket/connect', function(session){

            // The callback function in "subscribe" is called everytime an event is published in that channel.
            session.subscribe("en/communication/car/car/1", function(uri, payload){
                console.log("Received message", payload.msg);
            });
            let result = session.publish('en/communication/car/car/123', 'This is a message!');
            console.log('result', result);
        });*/

        return new Promise(function (resolve, reject) {
            request
                .get(url)
                .query(args)
                .then(function (response) {
                    if (response.statusCode === 200) {
                        resolve(JSON.parse(response.text));
                    } else {
                        reject(new Error(`API request failed with status code: ${response.statusCode}`));
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    /**
     * Send http request by method POST
     * @param {string} url
     * @param {object} data
     * @returns {Promise}
     */
    sendPostRequest(url, data) {
        return new Promise(function (resolve, reject) {
            //console.log('data', data);
            request
                .post(url)
                .send(data)
                .then(function (response) {
                    //console.log('response', response.text);
                    if (response.statusCode === 200) {
                        try {
                            const jsonResponse = JSON.parse(response.text); // Parse the response as JSON
                            resolve(jsonResponse); // Resolve with the parsed JSON response
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        reject(new Error(`API request failed with status code: ${response.statusCode}`));
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}