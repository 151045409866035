import { tns } from "tiny-slider";

class ComponentTestimonialsSlider {

    constructor() {
        this.tinySliderSection = document.querySelector(".hp-testimonials-slider");

        if (this.tinySliderSection) {
            this.slider = document.querySelector(".testimonials-slider-inner");

            this.sliderControls = document.querySelector(".testimonials-slider-controls");
            this.prevBtn = this.sliderControls.querySelector("[data-slider-prev]");
            this.nextBtn = this.sliderControls.querySelector("[data-slider-next]");

            this.navContainer = document.querySelector(".testimonials-slider-indicators");
            this.indicatorsList = this.navContainer.querySelector("[data-indicators-list]");

            this.initSlider();
        }
    }

    initSlider() {
       // const indicatorsList = this.indicatorsList;
        const spacer = 20;

        let tinySlider = tns({
            container: this.slider,
            items: 1,
            gutter: spacer,
            edgePadding: spacer,
            lazyload: true,
            lazyloadSelector: '.testimonials-slide-header img',
            controlsContainer: this.sliderControls,
            prevButton: this.prevBtn,
            nextButton: this.nextBtn,
            mouseDrag: true,
            center: true,
            nav: false,
            autoHeight: true,
            onInit: function(info) {
                info.controlsContainer.setAttribute('tabindex', -1);
                info.nextButton.setAttribute('tabindex', 0);
                info.prevButton.setAttribute('tabindex', 0);
            },
            //navContainer: this.indicatorsList,
            //fixedWidth: 390,
            /*responsive: {
                576: {
                    items: 1.25,
                    //gutter: 80,
                    //edgePadding: 25
                },
                768: {
                    items: 2,
                    //gutter: 80,
                    //edgePadding: 25
                },
                992: {
                    items: 2,
                    //gutter: 60,
                    //edgePadding: 20
                },
                1199: {
                    items: 2.75,
                    //gutter: 60,
                    //edgePadding: 20
                },
                1440: {
                    items: 3,
                    gutter: spacer*6,
                    edgePadding: spacer*6
                }
            }*/
        });

        //create dot indicators
        createIndicators();

        tinySlider.events.on( 'indexChanged', () => {
            //const displayIndex = tinySlider.getInfo().displayIndex;
            //const index = tinySlider.getInfo().index; // no loop => getInfo().index

            const indexLoop = tinySlider.getInfo().index - 1; // for loop => getInfo().index - 1

            refreshIndicatorActiveClasses();
        });

        /**
         * create slider indicators
         */
        function createIndicators() {
            const slideCount = tinySlider.getInfo().slideCount;
            const sliderId = tinySlider.getInfo().container.id;
            const indicatorsList = document.querySelector("[data-indicators-list]");

            for(let i = 0; i < slideCount; i++){
                const listEl = document.createElement("li");
                listEl.classList.add('slider-indicator');
                listEl.setAttribute('data-nav', i);
                listEl.setAttribute('tabindex', -1);
                listEl.setAttribute('aria-controls', sliderId);

                indicatorsList.appendChild(listEl);
            }

            indicatorsClick();
        }

        /**
         * init indicators click event
         */
        function indicatorsClick() {
            const indicatorsList = document.querySelector("[data-indicators-list]");
            const listItems = indicatorsList.querySelectorAll('.slider-indicator');

            for(let i = 0; i < listItems.length; i++){
                let slideIndex = listItems[i].getAttribute('data-nav');

                listItems[0].classList.add('is-active');
                listItems[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    tinySlider.goTo(Number(slideIndex));

                    /*listItems.forEach(node => {
                        node.classList.remove('is-active');
                    });
                    listItems[i].classList.add('is-active');*/

                    refreshIndicatorActiveClasses();
                });
            }
        }

        /**
         * refresh indicators active class after autoplay slide change
         */
        function refreshIndicatorActiveClasses() {
            const indicatorsList = document.querySelector("[data-indicators-list]");
            const listItems = indicatorsList.querySelectorAll('.slider-indicator');

            const info = tinySlider.getInfo();
            const displayIndex = info.displayIndex - 1;
            //const indexCurr = info.index;
            //const activeLi = indicatorsList.querySelector(`[data-nav="${indexCurr}"]`);

            listItems.forEach(node => {
                node.classList.remove('is-active');
            });

            listItems[displayIndex].classList.add('is-active');
            //activeLi.classList.add('is-active');
        }

    }

} export default ComponentTestimonialsSlider;
