import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SCROLL_TARGETS, scrollToElement } from "../utils/ScrollUtils";

const Footer = () => {
    const listOne = [
        { label: "Domů", link: "/" },
        {
            label: "O nás",
            link: "/o-nas",
            scroll: SCROLL_TARGETS.ABOUT,
            onClick: event => {
                scrollToElement(`${SCROLL_TARGETS.ABOUT}`, 'home');
            }
        },
        {
            label: "Naše služby",
            link: "/nase-sluzby",
            scroll: SCROLL_TARGETS.SERVICES,
            onClick: event => {
                scrollToElement(`${SCROLL_TARGETS.SERVICES}`, 'home');
            }
        },
        {
            label: "Kontakt",
            link: "/kontakt",
            scroll: SCROLL_TARGETS.CONTACT,
            onClick: event => {
                event.preventDefault();
                scrollToElement(`${SCROLL_TARGETS.CONTACT}`);
            }
        },
    ];
    const listTwo = [
        { label: "Kariéra", link: "/kariera" },
        { label: "Reference", link: "/reference" },
        /*{ label: "Daňový kalendář",
            link: "/danovy-kalendar",
            scroll: SCROLL_TARGETS.TAX_CALENDAR,
            onClick: event => {
                scrollToElement(`${SCROLL_TARGETS.TAX_CALENDAR}`, 'home');
            }
        },*/
        { label: "Používání souborů cookies", link: "/pouzivani-souboru-cookies" },
    ];

    return (
        <footer className="footer">
            <div className="container">
                <div className={'footer__wrapper'}>
                    <div>
                        <p className="footer-copyright">
                            &copy; 2020 Účetní a daňová kancelář T&amp;K s.r.o.<br/>
                            <span className="metud">
                                created by <a href="https://matejkvapil.cz" title="Matěj Kvapil, tvorba webu" target="_blank" rel="noopener noreferrer">
                                    matejkvapil.cz
                                </a>
                            </span>
                        </p>
                    </div>
                    <div>
                        <div className={'footer-nav__wrapper'}>
                            <div>
                                <ul className="footer-links-list">
                                    {listOne.map((link, index) => (
                                        <li key={index}>
                                            {link.onClick ? (
                                                <NavLink
                                                    className={`nav-link`}
                                                    to={link.link}
                                                    title={link.label}
                                                    onClick={link.onClick}
                                                >
                                                    {link.label}
                                                </NavLink>
                                            ) : (
                                                <NavLink className="nav-link" to={link.link} title={link.label}>{link.label}</NavLink>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <ul className="footer-links-list">
                                    {listTwo.map((link, index) => (
                                        <li key={index}>
                                            {link.onClick ? (
                                                <NavLink
                                                    className={`nav-link`}
                                                    to={link.link}
                                                    title={link.label}
                                                    onClick={link.onClick}
                                                >
                                                    {link.label}
                                                </NavLink>
                                            ) : (
                                                <NavLink className="nav-link" to={link.link} title={link.label}>{link.label}</NavLink>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
