import HttpClient from "./HttpClient";

export default class ApiClient {
    httpClient;
    localPath;

    constructor() {
        this.httpClient = new HttpClient();
        this.localPath = './rest/api';
    }
    /**
     * @returns {Promise}
     */
    findPartners() {
        return this.httpClient.sendGetRequest(
            `${this.localPath}/partners/get`,
        );
    }

    /**
     * @returns {Promise}
     */
    findServices() {
        return this.httpClient.sendGetRequest(
            `${this.localPath}/services/get`,
            {
                //authToken: generateAuthToken,
            },
        );
    }

    /**
     * @param {string} slug
     * @returns {Promise}
     */
    findServiceBySlug(slug) {
        return this.httpClient.sendGetRequest(
            `${this.localPath}/services/get?slug=${slug}`,
            {}
        );
    }

    /**
     * @returns {Promise}
     */
    findTestimonials() {
        return this.httpClient.sendGetRequest(
            `${this.localPath}/testimonials/get`,
            {}
        );
    }

    /**
     * @returns {Promise}
     */
    findPriceList(){
        return this.httpClient.sendGetRequest(
            `${this.localPath}/pricelist/get`,
            {}
        );
    }

    /**
     * Send http request by method POST
     * @param {object} formData
     * @returns {Promise}
     */
    async sendContactFormData(formData) {
        try {
            const response = await this.httpClient.sendPostRequest(
                `${this.localPath}/page/send-contact-form`,
                formData
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Send http request by method POST
     * @param {object} formData
     * @returns {Promise}
     */
    async sendServicesFormData(formData) {
        try {
            const response = await this.httpClient.sendPostRequest(
                `${this.localPath}/page/send-services-form`,
                formData
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    /**
     * @returns {Promise}
     */
    findCalendar() {
        return this.httpClient.sendGetRequest(
            `${this.localPath}/calendar/get`,
            {}
        );
    }

    /**
     * @param {int} id
     * @returns {Promise}
    removeCar(id) {
        return this.httpClient.sendGetRequest(
            'http://localhost/nette-react/www/homepage/remove-car',
            { id: id }
        );
    }*/

    /**
     * @param vendor
     * @param model
     * @param year
     * @param km
     * @returns {Promise}
    addCar(vendor, model, year, km) {
        return this.httpClient.sendGetRequest(
            'http://localhost/nette-react/www/homepage/add-car',
            { vendor: vendor, model: model,year: year, km: km }
        );
    }*/


    /**
     *
     * @param {number} id
     * @param {string} vendor
     * @param {string} model
     * @param {int} year
     * @param {int} km
     * @returns {Promise}
    editCar(id: number, vendor, model, year, km) {
        return this.httpClient.sendGetRequest(
            'http://localhost/nette-react/www/homepage/edit-car',
            { id: id, vendor: vendor, model: model, year: year, km: km }
        );
    }*/
}