import React, { Component } from 'react';
import ComponentOpenerBg from "../../../ComponentOpenerBg";
import {SEO} from "../../utils/SeoUtils";
//import CookieConsentConfig from '../../../cookieConsent/CookieConsentConfig';
//import 'vanilla-cookieconsent';
class Cookies extends Component {

    constructor() {
        super();
    }
    componentDidMount() {
        document.body.classList.add('page--service-detail');
        new ComponentOpenerBg();

        return () => {
            document.body.classList.remove('page--service-detail');
        };
    }

    render() {
        return (
            <React.Fragment>
                <SEO
                    title='Používání souborů cookies | T&K - Účetní a daňová kancelář'
                    description='Profesionální služby v oblasti účetnictví a daní. Podvojné účetnitcví, daňová evidence, mzdová a personální agenda, účetní poradenství.'
                    keywords='Podvojné účetnictví, daňová evidence, přiznání daně, zpracování mzdové agendy, účetní poradenství'
                    type='article'
                />
                <section className={'sp-intro'}>
                    <div className="sp-intro__bg" data-opener-bg="./assets/images/styles/home/hero-bg.jpg"></div>
                </section>
                <section className={'s-content s-content--sp'}>
                    <div className={'container'}>
                        <h1 className={'title title--sp-border'}>Používání souborů cookies</h1>
                    </div>
                    <div className={'container mt-4'}>
                        <div className={'sp-content'}>
                            <p>
                                Cookie je malý údaj (textový soubor), o jehož uložení požádá webová stránka (když je navštívena uživatelem)
                                Váš prohlížeč ve Vašem zařízení, aby si pamatoval informace o Vás. Soubory cookies umožňují „zapamatovat si“
                                Vaše akce nebo preference na omezenou dobu.
                            </p>

                            <p>V závislosti na typu souborů cookie, budou soubory cookie:</p>

                            <ul>
                                <li>
                                    Dočasně uložené v paměti Vašeho prohlížeče a zničeny, když se zavře („Relační Cookies“)
                                </li>
                                <li>
                                    Uložené do Vašeho počítače, takže když ho vypnete a znovu spustíte, stále tam mohou být („Trvalé Cookies“).
                                    Trvalé Cookies se vytvářejí tak, že se jim dá omezená životnost, která je uvedena pro každý soubor
                                    cookie v tabulkách níže.
                                </li>
                            </ul>

                            <p>
                                Používáme interní cookies, které nám umožňují zapamatovat si informace, jako jsou Váš
                                výběr jazyka nebo informace o Vašem připojení. Tyto cookies nastavujeme my a nazýváme je cookies
                                první strany. Používáme také cookies třetích stran, což jsou soubory cookies z jiné domény než je
                                doména navštívené webové stránky. Tyto soubory cookies třetích stran umožňují jiným organizacím obecně
                                nám pomoci analyzovat, jak se naše stránka používá, měřit počet návštěvníků stránky a zobrazovat reklamu.
                                Třetí strany, které působí na naší webové stránce a prostřednictvím ní ukládají soubory cookies do Vašeho zařízení,
                                jsou uvedeny v tabulkách níže pro každou kategorii souborů cookies. Pokud chcete získat více informací o těchto souborech
                                cookies třetích stran, přečtěte si o souborech cookies a/nebo zásady ochrany osobních údajů těchto třetích stran.
                            </p>

                            <p>
                                Popis každé kategorie souborů cookie, jakož i souborů cookies použitých na této webové stránce, jsou uvedeny níže.
                            </p>

                            <p>
                                Bez Vašeho předchozího souhlasu se ve Vašem přístroji neuloží žádný soubor cookie,
                                který není nezbytný k tomu, aby web fungoval. Globální souhlas můžete udělit pro všechny
                                kategorie souborů cookie, jednotlivě nebo můžete svůj souhlas kdykoliv odvolat na stránce „Používání souborů cookies“
                                kliknutím na následující tlačítko níže.
                            </p>
                        </div>
                    </div>
                    <div className={'container mt-4'}>
                        <p className={'par'}>
                            <button type="button"
                                    className="btn btn--blue--full"
                                    aria-label="Zobrazit nastavení cookies"
                                    onClick={() => cookieconsent.openPreferencesCenter()}
                            >
                                Své předvolby si můžete nastavit zde
                            </button>
                        </p>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Cookies;