import React from 'react';
import {SEO} from "../utils/SeoUtils";

const NotFound = () => {
    return (
        <React.Fragment>
            <SEO
                title='Stránka nenalezena | T&K - Účetní a daňová kancelář'
                description='Profesionální služby v oblasti účetnictví a daní. Podvojné účetnitcví, daňová evidence, mzdová a personální agenda, účetní poradenství.'
                keywords='Podvojné účetnictví, daňová evidence, přiznání daně, zpracování mzdové agendy, účetní poradenství'
                type='article'
            />
            <section className={'sp-intro'}>
                <div className="sp-intro__bg" style={{background: 'url(./assets/images/styles/home/hero-bg.jpg)'}}></div>
            </section>

            <section className={'s-content s-content--sp'}>
                <div className={'container text text--center mt-4'}>
                    <h1 className={'title title--sp mb-3'}>Chyba 404</h1>
                    <p>Požadovaná stránka nebyla nenalezena.</p>
                </div>
            </section>
        </React.Fragment>
    );
}

export default NotFound;