import React, { Component, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import Home from '../pages/Home/Home';
import Partners from "../pages/Partners/Partners";
import PriceList from "../pages/PriceList/PriceList";
import Career from "../pages/Career/Career";
import Cookies from "../pages/Cookies/Cookies";
import NotFound from '../pages/NotFound';
import Contact from "./Contact";
import ContactForm from "./ContactForm";
import ServiceDetail from "../pages/Services/ServiceDetail";
import { Helmet } from 'react-helmet-async';

const Layout = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/' || location.pathname === '/nase-sluzby' || location.pathname === '/o-nas'/* || location.pathname === '/danovy-kalendar'*/;

    return (
        <React.Fragment>
            <Helmet>
                {!isHomePage && (
                    <link rel="stylesheet" type="text/css" href="./assets/css/main.min.css" />
                )}
            </Helmet>
            <Header />

            <main role="main" className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} isHomePage={true} />
                    <Route path="/nase-sluzby" element={<Home />} isHomePage={true} />
                    <Route path="/o-nas" element={<Home />} isHomePage={true} />
                    <Route path="/reference" element={<Partners />} isHomePage={false} />
                    <Route path="/cenik" element={<PriceList />} isHomePage={false} />
                    <Route path="/kariera" element={<Career />} isHomePage={false} />
                    <Route path="/pouzivani-souboru-cookies" element={<Cookies />} isHomePage={false} />
                    {/*<Route path="/danovy-kalendar" element={<Home />} isHomePage={true} />*/}

                    <Route path="/:serviceSlug" element={<ServiceDetail />} isHomePage={false} />
                    {/* Speciální Route pro chybové stránky 404
                    <Route path="/*" element={<NotFound />} />*/}
                </Routes>
            </main>

            <Contact />
            <ContactForm />
            <Footer />
        </React.Fragment>
    );
}

export default Layout;